export default {
  data: () => ({
    loading: true,
    options: [],
    selected: null
  }),
  async mounted () {
    await this.getOptions()
  },
  watch: {
    async selected (val) {
      if (val === null) return

      await this.getReports(val)
    }
  },
  methods: {
    async getOptions () {
      const url = '/admin/reports/options'

      await this.$http.get(url)
        .then(res => {
          this.options = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getReports (channel) {
      this.loading = true

      const url = `/admin/reports/content/${channel}`

      await this.$http
        .get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
          this.totalRows = this.items.length
        })
    }
  }
}
